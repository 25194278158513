import React, { Component } from 'react';
import translations from '../../../../translations.json'
import Lottie from 'lottie-react'


import logo from '../../../../assets/img/work-logo@2x.png';
import logoWithoutText from '../../../../assets/img/logo-without-text.svg';
import menu from '../../../../assets/img/menu.svg';
import revert from '../../../../assets/img/work-revert.svg';
import update from '../../../../assets/img/work-update.svg';
import full from '../../../../assets/img/work-full-screen.svg';
import profile from '../../../../assets/img/work-profile@3x.svg';
import emailRound from '../../../../assets/img/email-round-active.svg';
import mail from '../../../../assets/img/mail.svg';
import mailActive from '../../../../assets/img/mail-active.svg';
import phone from '../../../../assets/img/phone.svg';
import phoneActive from '../../../../assets/img/phone-active.svg';
import item2 from '../../../../assets/img/work-item2.svg';
import item3 from '../../../../assets/img/work-item3.svg';
import itemAlt2 from '../../../../assets/img/work-item-alt2.svg';
import itemAlt3 from '../../../../assets/img/work-item-alt3.svg';
import toolbarinactive from '../../../../assets/img/toolbar-inactive.svg';
import toolbaractive from '../../../../assets/img/toolbar-active.svg';
import loader from '../../../../assets/lottie/loader.json'

export default class PageHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenedProfile: false,
            isOpenedContact: false,
            isOpenLangPicker: false,
            isMenuOpen: true,
            hoverProfileNumber: -1,
        };
    }

     //TODO Load profile items from container
    profileItemsAction = idx => {
        this.setState({ isOpenedProfile: false })
        switch (idx) {
            case 0: this.props.redirectToFleetbase(); break;
            case 1: this.props.redirectToIrisnet(); break;
            case 2: this.props.handleLogout(); break;
            default: break;
        }
    }


    onClickLangPicker = (value) => {
        if ((value !== undefined) && this.state.isOpenLangPicker) this.setState({ isOpenLangPicker: !!value });
        this.setState(({ isOpenLangPicker }) => ({ isOpenLangPicker: !isOpenLangPicker }));
    }

    onClickMenuToggle = () => {
        this.props.handleMenuToggle( !this.props.isMenuOpen );
    }

    render() {

        const { activeLanguage, onChangeLanguage, revertAllAsync, toggleToolbar, refreshDataAsync, openFullScreen, user } = this.props;
        const { isOpenedProfile, isOpenedContact, isOpenLangPicker, hoverProfileNumber, hoverContactNumber } = this.state;
        const profileItems = [
            { name: translations[activeLanguage].values['go_fleetbase'], img: item2, imgAlt: itemAlt2 },
            { name: translations[activeLanguage].values['go_irisnet'], img: item2, imgAlt: itemAlt2 },
            { name: translations[activeLanguage].values['log_out'], img: item3, imgAlt: itemAlt3 },
        ];
        const contactItems = [
            { phoneOrMail: translations[activeLanguage].values['contactPhone'], img: phone, imgAlt: phoneActive, type: 'tel' },
            { phoneOrMail: translations[activeLanguage].values['contactMail'], img: mail, imgAlt: mailActive, type: 'mailto' },
        ];
        return (
            <header className={`workspace__header ${!this.props.isToolbarHidden ? "toolbar-is-open" : ""}`}>
                <div className="workspace__left-side-header">
                    <div className="workspace__mobile-menu-toggle">
                        <div
                            onClick={() => {
                                this.onClickMenuToggle();
                                if (isOpenedProfile) this.setState({ isOpenedProfile: false });
                                if (isOpenedContact) this.setState({ isOpenedContact: false });
                                if (isOpenLangPicker) this.setState({ isOpenLangPicker: false });
                            }}
                        >

                            <img className="workspace__mobile-menu" src={menu} alt="Mobile menu" />
                        </div>
                    </div>
                      <img className="workspace__logo" src={logo} alt="IRIS VIEWS logo" />
                      <img className="workspace__logo-without_text" src={logoWithoutText} alt="IRIS VIEWS logo" />
                    <div className="workspace__stick"></div>
                    <div className="loading-animation">
                    {this.props.isLoadingResources ? <Lottie style={{margin:'0x'}} animationData={loader} /> : null }
                    </div>
                </div>
                <div className="workspace__right-side-header">
                    <div className="workspace__icons-container">
                        <div className="workspace__icon-wrapper" data-title={translations[activeLanguage].values['toggleToolbar']}>
                            <img onClick={toggleToolbar} className="workspace__icon workspace__icon-revert" src={(this.props.isToolbarHidden) ? toolbarinactive : toolbaractive} alt="revert" />
                        </div>
                        <div className="workspace__icon-wrapper" data-title={translations[activeLanguage].values['revert']}>
                            <img onClick={revertAllAsync} className="workspace__icon workspace__icon-revert" src={revert} alt="revert" />
                        </div>
                        <div className="workspace__icon-wrapper" data-title={translations[activeLanguage].values['refresh']}>
                            <img onClick={refreshDataAsync} className="workspace__icon workspace__icon-update" src={update} alt="update" />
                        </div>

                        <div className="workspace__icon-wrapper" data-title={translations[activeLanguage].values['full_screen']}>
                            <img onClick={openFullScreen} className="workspace__icon workspace__icon-full" src={full} alt="full screen" />
                        </div>
                    </div>
                    <div className="workspace__stick"></div>
                    <div className="workspace__language-picker">
                        <div
                            onClick={() => {
                                this.onClickLangPicker();
                                if (isOpenedProfile) this.setState({ isOpenedProfile: false });
                                if (isOpenedContact) this.setState({ isOpenedContact: false });
                            }}
                            className="workspace__language-click-container"
                        >
                            <span className="workspace__language workspace__language-picker_transparent_20">{translations[activeLanguage].values['language']}: </span>
                            <span className="workspace__language">{translations[activeLanguage].name}</span>
                            <span className={isOpenLangPicker ? "workspace__triangle_active" : "workspace__triangle"}></span>
                        </div>
                        {(!translations || !translations.length)
                            ? null
                            : <div
                                className={isOpenLangPicker ? "workspace__language-list workspace__language-list_active" : "workspace__language-list"}
                                ref={list => this.languageList = list}
                            >
                                <div className="workspace__language-list-nouse"></div>
                                {translations.map(({ name }) => name).map((lang, idx) => (
                                    <div
                                        className="workspace__language-list-item"
                                        key={lang}
                                        onClick={() => {
                                            onChangeLanguage(idx);
                                            this.onClickLangPicker();
                                        }}
                                    >
                                        {lang}
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                    <div className="workspace__stick"></div>



                    <div title={translations[activeLanguage].values['contact']} className="workspace__contact">
                        <img
                            onClick={() => {
                                if (isOpenLangPicker) this.onClickLangPicker();
                                this.setState(({ isOpenedContact }) => ({ isOpenedContact: !isOpenedContact }));
                                if (isOpenedProfile) this.setState({ isOpenedProfile: false });
                            }}
                            className="workspace__contact-img" src={emailRound} alt="contact"
                        />
                        <div
                            className={isOpenedContact ? "workspace__contact-list workspace__contact-list_active" : "workspace__contact-list"}
                            ref={list => this.languageList = list}
                        >
                        <div className="workspace__contact-list-nouse"></div>

                        {contactItems.filter(function({ phoneOrMail, img, imgAlt, type }){
                            // filter for Italy, where we don't have a phone number
                            return phoneOrMail !== "";
                        }).map(({ phoneOrMail, img, imgAlt, type }, idx)=>(
                            <a
                                href={type + ':' + phoneOrMail}
                                className="workspace__contact-list-item"
                                onMouseEnter={() => this.setState({ hoverContactNumber: idx })}
                                onMouseLeave={() => this.setState({ hoverContactNumber: -1 })}
                                key={idx}
                            >
                            <img className="workspace__contact-list-img" src={idx === hoverContactNumber ? imgAlt : img} alt={name} />
                            <span className="workspace__contact-list-word">{phoneOrMail}</span>
                            </a>
                        ))}
                        </div>
                    </div>


                    <div title={user.profile.unique_name} className="workspace__profile">
                        <img
                            onClick={() => {
                                if (isOpenLangPicker) this.onClickLangPicker();
                                this.setState(({ isOpenedProfile }) => ({ isOpenedProfile: !isOpenedProfile }));
                                if (isOpenedContact) this.setState({ isOpenedContact: false });
                            }}
                            className="workspace__profile-img" src={profile} alt="profile"
                        />
                        <div
                            className={isOpenedProfile ? "workspace__profile-list workspace__profile-list_active" : "workspace__profile-list"}
                            ref={list => this.languageList = list}
                        >
                            <div className="workspace__profile-list-nouse"></div>
                            {profileItems.map(({ name, img, imgAlt }, idx) => (
                                <div
                                    className="workspace__profile-list-item"
                                    onMouseEnter={() => this.setState({ hoverProfileNumber: idx })}
                                    onMouseLeave={() => this.setState({ hoverProfileNumber: -1 })}
                                    key={idx}
                                    onClick={() => this.profileItemsAction(idx)}
                                >
                                    <img className="workspace__profile-list-img" src={idx === hoverProfileNumber ? imgAlt : img} alt={name} />
                                    <span className="workspace__profile-list-word">{name}</span>
                                </div>)
                            )}
                        </div>

                    </div>
                </div>
            </header>)
    }
}