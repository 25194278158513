import 'react-app-polyfill/ie9';
import 'core-js/es6/map';
import 'core-js/es6/set';
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./App";
import { authContext } from './libs/adal'
import ReactAI from 'react-appinsights';
import config from './config'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
 
ReactAI.init({instrumentationKey: config.APP_CONFIG.aiInstrumentationKey, debug: true});



authContext.handleWindowCallback();
//prevent iframe double app
if (window === window.parent) {
  if (!authContext.isCallback(window.location.hash)) {

    const router = createBrowserRouter([
      {
        path: "/",
        element: <App />,
        errorElement: <App />,
      },
      {
        path: "/*",
        element: <App />,
      },
    ]);


    ReactDOM.createRoot(document.getElementById("root")).render(
        <React.StrictMode>
          <RouterProvider router={router} />
        </React.StrictMode>
    );
  }
}

// Service Worker is needed, to turn the app into a Progressive Web App. It provides a local offline cache for the app.
serviceWorkerRegistration.register();