import React, { Component } from 'react';
import translations from '../../../../translations.json'
import { Collapse } from 'reactstrap';

import pdfFile from '../../../../assets/img/work-pdf-file@2x.svg'
import imageFile from '../../../../assets/img/work-image-file@2x.svg';
import dataFile from '../../../../assets/img/work-data-file@2x.svg';
import crosstabFile from '../../../../assets/img/work-crosstab-file@2x.svg';

export default class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            searchQuery: '',
            sitesList: null,
        };
    }

    handleChange = (e) => {
        //Uncollapse currently collapsed site in menu, if any site is collapsed
       // this.props.toggle("___nosite");

        const searchPhrase = e.target.value.toLowerCase().trim();

        let results = [];

        if (searchPhrase === "") {
            results = this.props.sites;
        }
        else {
            const allSites = this.props.sites;

            function nameContains(elementName, input){
                return elementName.toLowerCase().includes(input);
            }

            for (let i=0;i< allSites.length;i++){
                const site = allSites[i];

                //site name matches, take all workbooks
                if (nameContains(site.name, searchPhrase)){
                    results.push(site);
                }
                //site name does not match, check workbooks and only take workbooks with matching name
                else {
                    let matchingWorkbooks = [];

                    for ( let j = 0; j < site.workbooks.length; j++ ) {
                        const workbook = site.workbooks[j];

                        // workbook name matches, take the workbook
                        if (nameContains(workbook.name, searchPhrase)){
                            matchingWorkbooks.push(workbook);
                        }
                        //workbook name does not match, check dashboards and only take dashboards with matching name
                        else {
                            let matchingDashboards = [];
                            for ( let k = 0; k < workbook.dashboards.length; k++ ) {
                                const dashboard = workbook.dashboards[k];

                                // workbook name matches, take the workbook
                                if (nameContains(dashboard.name, searchPhrase)){
                                    matchingDashboards.push(dashboard);
                                }
                            }
                            if (matchingDashboards.length)
                            {
                                const dashboardsOnlyObject = { dashboards: matchingDashboards };

                                matchingWorkbooks.push({ ...workbook, ...dashboardsOnlyObject });
                            }
                        }
                    }

                    if (matchingWorkbooks.length)
                    {
                        const workbooksOnlyObject = { workbooks: matchingWorkbooks };

                        results.push({ ...site, ...workbooksOnlyObject });
                    }


                }
            }
        }

        if (results.length === 1){
            this.props.toggle(results[0].name);
        }

        this.setState({
            searchQuery: e.target.value,
            sitesList: results
        });
    }

    
    render() {
        const { activeLanguage, sites, goToNewPath, activeWorkbookId, activeDashboard, downloadImage, downloadFile, exportToPDF, exportAsCrosstab, toggle, collapse } = this.props;
        const sitesToDisplay = this.state.sitesList || sites || [];

        return (
            <aside className={`workspace__sidebar ${this.props.isMenuOpen ? "menu-is-open" : ""} ${!this.props.toolbarHidden ? "toolbar-is-open" : ""}`}>
            <div className={`workspace__sidebar-fullscreen-container ${!this.props.toolbarHidden ? "toolbar-is-open" : ""}`}>
                <div className="workspace__dashboards-container">
                    <div className="workspace__dashboards-name">{translations[activeLanguage].values['dashboards']}</div>
                    <div className="workspace__horizontal-line" />
                    <div className="workspace__dashboards-list">
                        {!sites
                            ? <div className="workspace__dashboards-list-dashboard">Loading...  </div>
                            :
                            sites.length > 2
                            ? 
                            // There are more sites. Show the sites on a top level. Make them expandable. Show workbooks with dashboards below.
                                        <div>
                                            <form>
                                                <input id="search-site" onChange={this.handleChange} value={this.state.searchQuery} placeholder={translations[activeLanguage].values['search']} type="search"/>
                                            </form>
                                            {sitesToDisplay
                                                        .map((site, i) => {
                                                            site.order = i;
                                                            return <div key={i}>
                                                                <div
                                                                    id={site.id} className="workspace__dashboards-list-workbook workspace__dashboards-list-site" onClick={toggle.bind(this, site.name)}
                                                                >
                                                                    {site.name}
                                                                </div>

                                                                {(() => {
                                                                    // if last item in list, remove the horizontal line
                                                                    if(!(sites.length === i +1)) {
                                                                        return <div className="workspace__horizontal-line"></div>;
                                                                    }
                                                                })()}

                                                                <div>
                                                                    {(() => {
                                                                        if (site.workbooks.length) {
                                                                            return site.workbooks
                                                                                .map((workbook, i) =>
                                                                                    !workbook.dashboards.length ? null : (

                                                                                        <div key={i}>
                                                                                            <Collapse isOpen={collapse === site.name} >



                                                                                                <div id={workbook.id}
                                                                                                     onClick={() => goToNewPath(workbook.dashboards[0], workbook.id, site.contentUrl, true)}
                                                                                                     className="workspace__dashboards-list-workbook"
                                                                                                >
                                                                                                    {workbook.name}
                                                                                                </div>
                                                                                                {workbook.dashboards.map((dashboard) => (
                                                                                                    <div
                                                                                                        className={activeWorkbookId === workbook.id && activeDashboard === dashboard.name
                                                                                                            ? "workspace__dashboards-list-dashboard workspace__dashboards-list-dashboard_active"
                                                                                                            : "workspace__dashboards-list-dashboard"
                                                                                                        }
                                                                                                        key={dashboard.id}
                                                                                                        onClick={() => goToNewPath(dashboard, workbook.id, site.contentUrl, true)}
                                                                                                    >
                                                                                                        {dashboard.name}
                                                                                                    </div>
                                                                                                ))}
                                                                                            </Collapse>
                                                                                        </div>
                                                                                    ))
                                                                        } else {
                                                                            return []
                                                                        }
                                                                    })()}
                                                                </div>
                                                            </div>
                                                        })
                                            }
                                        </div>

                        : 
                        // There is only one site. Hide the sitename. Show all workbooks with all dashboards.
                        sites.map((site) => {
                            if (site.workbooks.length) {
                                return site.workbooks
                                .map((workbook, i) =>
                                    !workbook.dashboards.length ? null : (
                                        <div key={i}>
                                            <div
                                                onClick={() => goToNewPath(workbook.dashboards[0], workbook.id, site.contentUrl, true)}
                                                className="workspace__dashboards-list-workbook"
                                            >
                                                {workbook.name}
                                            </div>
                                            {workbook.dashboards.map((dashboard) => (
                                                <div
                                                    className={activeWorkbookId === workbook.id && activeDashboard === dashboard.name
                                                        ? "workspace__dashboards-list-dashboard workspace__dashboards-list-dashboard_active"
                                                        : "workspace__dashboards-list-dashboard"
                                                    }
                                                    key={dashboard.id}
                                                    onClick={() => goToNewPath(dashboard, workbook.id, site.contentUrl, true)}
                                                >
                                                    {dashboard.name}
                                                </div>
                                            ))}
                                        </div>
                                    ))
                            } else {
                                return []
                            }

                        })
                        }
                    </div>
                </div>
                <div className="workspace__horizontal-line" />
                <div className="workspace__aside-part2">
                    <div className="workspace__download">{translations[activeLanguage].values['download']}</div>
                    <p className="workspace__download-description">{translations[activeLanguage].values['save_all']}</p>
                    <div className="workspace__download-icons-block">
                        <div onClick={exportToPDF} className="workspace__download-icon-wrapper workspace__download-icon-wrapper_pdf" data-title="PDF" >
                            <img alt="Download PDF" className="workspace__download-icon" src={pdfFile} data-title="PDF" />
                        </div>
                        <div onClick={downloadImage} className="workspace__download-icon-wrapper workspace__download-icon-wrapper_image" data-title="Image" >
                            <img alt="Download Screenshot" className="workspace__download-icon" src={imageFile} data-title="Image" />
                        </div>
                        <div onClick={downloadFile} className="workspace__download-icon-wrapper workspace__download-icon-wrapper_data" data-title="Data" >
                            <img alt="Download Data" className="workspace__download-icon" src={dataFile} />
                        </div>
                        <div onClick={exportAsCrosstab} className="workspace__download-icon-wrapper workspace__download-icon-wrapper_data" data-title="Crosstab" >
                            <img alt="Download Crosstab" className="workspace__download-icon" src={crosstabFile} />
                        </div>
                    </div>
                </div>
                <footer className="workspace__aside-footer">Powered by dataforce</footer>
            </div>

        </aside>
        )
    }
}

